import React from "react";
import construction from '../images/Construction.png';
 
const UnderConstruction = () => {
  return (
    <div className="flex flex-col items-center font-sofia justify-center min-h-screen bg-black text-white">
      <div className="flex flex-col items-center justify-center w-full max-w-[60%] mb-48"> {/* Flex column for stacking image and text */}
        <img src={construction} alt="Under Construction" className="w-72 h-auto mb-4" /> {/* Adjusted size and margin for spacing */}
        <div className="text-5xl font-bold text-white">
          Page Under Construction
        </div>
        <p className="text-white mt-4">
        We are working hard to bring you this page. Please check back later.
      </p>
      </div>
    </div>
  );
};
 
export default UnderConstruction;
 
 