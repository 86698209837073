import React from 'react';
import pharma from '../images/phama.png'
import jhub from '../images/jhub.png'
import dlab from '../images/dlabs.png'
import mety from '../images/Meity.png'
import nasscom from '../images/nasscom_ai_logo.jpeg'
import founder from '../images/founder.png'
import umg from '../images/umg.png'
import aic from '../images/aic.png'
import '../style/collabarators.css';
 
const Collabarators = () => {
    return (
        <>
            <div className="bg-black text-white px-4 lg:px-12 py-12 font-sofia">
                <div className="container mx-auto">
                    <div className="text-center text-3xl sm:text-4xl md:text-5xl font-bold text-left mt-6 ">
                        Welcome to our Collaborative ecosystem
                    </div>
                    <div className="text-lg text-[#6FC5F0] text-center tracking-[3px] font-semibold sm:text-xl text-left mt-4">
                        Driving Innovation together for building tomorrow's therapeutics
                    </div>
                    <div className="text-lg md:text-xl text-left mt-4 opacity-75">
                        We are proud to be associated with global partners. Explore our network of collaborators and witness the transformative impact strategic alliances in shaping the future.
                    </div>
                    <div className="scroll-container mt-12 overflow-x-auto">
                        <div className="scroll-content flex items-center justify-start space-x-4 sm:space-x-8">
                            {/* Wrap each image in a div with consistent width */}
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={dlab} alt="d-labs logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={mety} alt="Meity logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={umg} alt="umg logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={founder} alt="founder logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={nasscom} alt="nasscom logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={aic} alt="aic logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={jhub} alt="jhub logo" />
                            </div>
                            {/* Duplicate images for seamless scrolling */}
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={dlab} alt="d-labs logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={mety} alt="Meity logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={umg} alt="umg logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={founder} alt="founder logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={nasscom} alt="nasscom logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={aic} alt="aic logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={jhub} alt="jhub logo" />
                            </div>
                            <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 bg-white rounded-[50px] p-2 flex justify-center items-center">
                                <img className="h-12 sm:h-16 md:h-24 object-contain" src={pharma} alt="pharma logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Collabarators;
 
 