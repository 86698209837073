/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
 
const PrivacyPolicy = () => {
    return (
        <div className='bg-black text-white px-24 '>
            <h1 className='text-center text-2xl'>Privacy Policy</h1>
            <p className='py-2'>
                Centella Scientific Private Limited (referred to as "Centella," "us," "we," or "our") operates the Centella website (www.centella.co.in).
                This document outlines our policies regarding the collection, use, and disclosure of personal data when you use our services and the choices available to you concerning that data.
            </p>
            <p className='py-2' >
                We use your data to provide and enhance our services. By utilizing our services, you agree to the collection and use of information per this policy.
                Unless specified otherwise, terms used in this Privacy Policy carry the same meanings as defined in our Terms and Conditions, accessible from our website.
            </p>
 
            <h2 className='text-center text-2xl'>Definitions</h2>
            <h3>Service</h3>
            <p className='py-2'>The website is operated by Centella AI Therapeutics.</p>
 
            <h3 className='text-center text-2xl'>Personal Data</h3>
            <p className='py-2'>Information about a living individual who can be identified from that data (or from those and other information either in our possession or likely to come into our possession).</p>
 
            <h3 className='text-center text-2xl'>Usage Data</h3>
            <p className='py-2'>Data collected automatically, either generated by the use of the service or from the service infrastructure itself (e.g., the duration of a page visit).</p>
 
            <h3 className='text-center text-2xl'>Cookies</h3>
            <p className='py-2'>Small pieces of data stored on your device (computer or mobile device).</p>
 
            <h3 className='text-center text-2xl'>Data Controller</h3>
            <p className='py-2'>A person or entity who (either alone, jointly, or in common with other persons) determines the purposes and manner in which any personal information is processed. For this Privacy Policy, we are the Data Controller of your Personal Data.</p>
 
            <h3 className='text-center text-2xl'>Data Processors (or Service Providers)</h3>
            <p>Any person or entity who processes data on behalf of the Data Controller. We may use the services of various Service Providers to process your data more effectively.</p>
 
            <h3 className='text-center text-2xl'>Data Subject (or User)</h3>
            <p className='py-2'>Any living individual who uses our service and is the subject of Personal Data.</p>
 
            <h2>Information Collection and Use</h2>
            <p className='py-2'>We collect different types of information for various purposes to provide and improve our service to you.</p>
 
            <h3 className='text-center text-2xl'>Types of Data Collected</h3>
 
            <h4 className='text-center text-2xl'>Personal Data</h4>
            <p className='py-2'>
                While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data").
                This may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Cookies and Usage Data</li>
            </ul>
            <p className='py-2'>
                We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may interest you.
                You may opt out of receiving any, or all, of these communications from us by contacting us.
            </p>
 
            <h4 className='text-center text-2xl'>Usage Data</h4>
            <p className='py-2'>
                We may also collect information on how the service is accessed and used ("Usage Data").
                This may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our service that you visit,
                the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
            </p>
 
            <h3 className='text-center text-2xl'>Tracking & Cookies Data</h3>
            <p className='py-2'>
                We utilize cookies and similar tracking technologies to monitor activities on our service and store certain information.
                Cookies are small data files that may include an anonymous unique identifier. These cookies are sent to your browser from a website and stored on your device.
                In addition to cookies, we use beacons, tags, and scripts to collect and track information, enhancing and analyzing our service.
            </p>
            <p className='py-2'>
                You can configure your browser to refuse all cookies or to notify you when a cookie is being sent. However, if you decline cookies, some portions of our service may not function properly.
            </p>
 
            <h3 className='text-center text-2xl'>Examples of Cookies We Use</h3>
            <ul>
                <li>Session Cookies: Used to operate our service.</li>
                <li>Preference Cookies: Used to remember your preferences and settings.</li>
                <li>Security Cookies: Used for security purposes.</li>
            </ul>
 
            <h3 className='text-center text-2xl'>Use of Data</h3>
            <p className='py-2'>Centella AI uses the collected data for various purposes, including:</p>
            <ul>
                <li>Providing and maintaining our service.</li>
                <li>Notifying you about changes to our service.</li>
                <li>Allowing you to participate in interactive features of our service at your discretion.</li>
                <li>Providing customer support.</li>
                <li>Gathering analysis or valuable information to improve our service.</li>
                <li>Monitoring the usage of our service.</li>
                <li>Detecting, preventing, and addressing technical issues.</li>
                <li>
                    Providing you with news, special offers, and general information about other goods, services, and events we offer,
                    similar to those you have already purchased or inquired about, unless you opt out of receiving such information.
                </li>
            </ul>
 
            <h3 className='text-center text-2xl'>Legal Basis for Processing Personal Data Under GDPR</h3>
            <p className='py-2'>
                For individuals from the European Economic Area (EEA), Centella AI's legal basis for collecting and using personal information,
                as described in this Privacy Policy, depends on the specific Personal Data we collect and the context in which we collect it. Centella AI may process your Personal Data because:
            </p>
            <ul>
                <li>We need to perform a contract with you.</li>
                <li>You have given us permission to do so.</li>
                <li>The processing is in our legitimate interests and is not overridden by your rights.</li>
                <li>To comply with the law.</li>
            </ul>
 
            <h3 className='text-center text-2xl'>Retention of Data</h3>
            <p className='py-2'>
                Centella AI will retain your Personal Data only as long as necessary for the purposes outlined in this Privacy Policy.
                We will retain and use your Personal Data to the extent required to comply with our legal obligations
                (e.g., if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p className='py-2'>
                Centella AI will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except when this data is used to enhance the security or functionality of our service,
                or when we are legally required to retain this data for extended periods.
            </p>
 
            <h3 className='text-center text-2xl'>Transfer of Data</h3>
            <p className='py-2'>
                Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country,
                or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.
            </p>
            <p className='py-2'>
                If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to India for processing.
            </p>
            <p className='py-2'>
                By consenting to this Privacy Policy and submitting your information, you agree to this transfer.
                Centella AI will take all reasonably necessary steps to ensure your data is treated securely and in accordance with this Privacy Policy.
                No transfer of your Personal Data will occur to an organization or country unless there are adequate controls in place, including the security of your data and other personal information.
            </p>
 
            <h3 className='text-center text-2xl'>Disclosure of Data</h3>
            <h4>Disclosure for Law Enforcement</h4>
            <p className='py-2'>
                Under certain circumstances, Centella AI may be required to disclose your Personal Data if mandated by law or in response to valid requests by public authorities
                (e.g., a court or a government agency).
            </p>
 
            <h4 className='text-center text-2xl'>Legal Requirements</h4>
            <p className='py-2'>
                Centella AI may disclose your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul>
                <li>Comply with a legal obligation.</li>
                <li>Protect and defend the rights or property of Centella AI.</li>
                <li>Prevent or investigate possible wrongdoing in connection with the service.</li>
                <li>Protect the personal safety of users of the service or the public.</li>
                <li>Protect against legal liability.</li>
            </ul>
 
            <h3 className='text-center text-2xl'>Security of Data</h3>
            <p className='py-2'>
                The security of your data is important to us. However, please remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
                While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            </p>
 
            <h3 className='text-center text-2xl'>“Do Not Track” Signals</h3>
            <p className='py-2'>
                We do not support Do Not Track (“DNT”) signals. Do Not Track is a preference you can set in your web browser to inform websites that you do not wish to be tracked.
            </p>
 
            <h3 className='text-center text-2xl'>Your Data Protection Rights Under GDPR</h3>
            <p className='py-2'>
                If you reside in the European Economic Area (EEA), you have specific data protection rights.
                Centella AI aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
            </p>
            <p className='py-2'>
                If you wish to be informed about the Personal Data we hold about you and want it removed from our systems, please contact us.
            </p>
            <p className='py-2'>In certain circumstances, you have the following data protection rights:</p>
            <ul>
                <li>
                    The right to access, update, or delete the information we have on you.
                    Whenever possible, you can access, update, or request the deletion of your Personal Data directly within your account settings section.
                    If you cannot perform these actions yourself, please contact us for assistance.
                </li>
                <li>The right of rectification: You have the right to have your information rectified if it is inaccurate or incomplete.</li>
                <li>The right to object: You have the right to object to our processing of your Personal Data. </li>
                <li>The right of restriction: You have the right to request that we restrict the processing of your personal information. </li>
                <li>The right to data portability: You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format. </li>
                <li>The right to withdraw consent: You also have the right to withdraw your consent at any time where Centella AI relied on your consent to process your personal information. </li>
            </ul>
            <p className='py-2'>Please note that we may ask you to verify your identity before responding to such requests. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA). </p>
            <h1 className='text-center text-2xl'>Service Providers </h1>
            <p className='py-2'>We may engage third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related tasks, or assist us in analyzing how our Service is utilized. These third parties have access to your Personal Data solely to perform these functions on our behalf and are obligated not to disclose or use it for any other purpose. </p>
            <h1 className='text-center text-2xl'>Analytics</h1>
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.
 
                Google Analytics: Google Analytics is a web analytics service provided by Google that tracks and reports website traffic. Google uses the data collected to monitor the usage of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize ads within its advertising network. You can opt-out of making your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. This add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. For more information on Google’s privacy practices, please visit the [Google Privacy & Terms web page] (https://policies.google.com/privacy?hl=en). </p>
            <h1 className='text-center text-2xl'>Link to other sites</h1>
            <p className='py-2'>Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. </p>
            <h1>Children's Privacy</h1>
            <p className='py-2'>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from individuals under 18. If you are a parent or guardian and you become aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we will take steps to remove that information from our servers. </p>
            <h1 className='text-center text-2xl'>Changes to This Privacy Policy </h1>
            <p className='py-2'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on this page. We will also inform you via email and/or a prominent notice on our Service prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
 
                This site is protected Google Privacy Policy (https://policies.google.com/privacy) and Terms of Service (https://policies.google.com/terms) apply.
 
                Contact Us </p>
            <h1 className='text-center text-2xl'>Contact us</h1>
            <p className='py-2'>If you have any questions about this Privacy Policy, please contact us:
 
                By email: <a href="" className='text-underline text-blue-400'>connect@centella.co.in</a>
 
                Copyright © 2024 CENTELLA SCIENTIFIC Private Limited All rights reserved. </p>
        </div>
    )
}
 
export default PrivacyPolicy