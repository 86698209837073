import React from "react";
import { useInView } from 'react-intersection-observer';
import Genai from '../images/Designer (8).png';
import virtual from '../images/virtualscreen-2.png';
import ADMET from '../images/Designer (21).png';
import retro from '../images/Designer (20).png';
import shield from '../images/shield.png';
import '../style/home.css'; // Add your custom styles here

const Platform = () => {
    // Set up Intersection Observers for each image
    const { ref: genaiRef, inView: genaiInView } = useInView({ triggerOnce: true });
    const { ref: admetRef, inView: admetInView } = useInView({ triggerOnce: true });
    const { ref: retroRef, inView: retroInView } = useInView({ triggerOnce: true });
    const { ref: virtualRef, inView: virtualInView } = useInView({ triggerOnce: true });

    return (
        <>
            <div className="bg-black text-white  px-4 sm:px-8 lg:px-24 xl:px-32 font-sofia">
                <div className="">
                    <div className="text-5xl font-bold text-[#6FC5F0] text-start mt-36">Centella AI Platform</div>
 
                    {/* Generative AI Section */}
                    <div className="flex flex-col-reverse lg:flex-row items-center justify-between p-6 lg:p-12">
                        <div className={`w-full lg:w-1/2 pr-0 lg:pr-8 ${genaiInView ? 'slide-in-left' : ''}`} ref={genaiRef}>
                            <h4 className="text-3xl font-semibold mb-8 text-start">Generative AI Chem</h4>
                            <ul className="mb-6 text-justify text-lg">
                                {['Built on cutting edge Active Learning ML algorithm.', 'Hybrid Molecular Representation.', 'Reinforcement Learning for Compound Generation.', 'Multi-Objective Optimization.', 'Integrated Predictive ADMET Modelling.', 'Spatial Awareness.'].map((item, idx) => (
                                    <li key={idx} className="flex items-center mb-2 py-2">
                                        <img src={shield} height={20} width={20} alt="Lab Icon" className="inline-block mr-2" />
                                        <span className="text-lg text-[#BCCACA] ml-2">{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={`w-full lg:w-1/2 mt-6 lg:mt-0 ${genaiInView ? 'slide-in-right' : ''}`}>
                            <img src={Genai} alt="Generative AI Chem" className="w-full lg:w-[70%] lg:h-[70%] h-auto max-w-md sm:max-w-lg lg:max-w-sm xl:max-w-md 2xl:max-w-lg rounded-[50px]" />
                        </div>
                    </div>
 
                    {/* ADMET Section */}
                    <div className="flex flex-col lg:flex-row items-center justify-between p-6 lg:p-12 mt-12">
                        <div className={`w-full lg:w-1/2 mt-6 lg:mt-0 ${admetInView ? 'slide-in-left' : ''}`} ref={admetRef}>
                            <img src={ADMET} alt="ADMET" className="w-full lg:w-[70%] lg:h-[70%] h-auto max-w-md sm:max-w-lg lg:max-w-sm xl:max-w-md 2xl:max-w-lg rounded-[50px]" />
                        </div>
                        <div className={`w-full lg:w-1/2 pr-0 lg:pr-8 ml-6 ${admetInView ? 'slide-in-right' : ''}`}>
                            <h4 className="text-2xl font-semibold mb-8 text-start">ADMET</h4>
                            <ul className="mb-6 text-justify text-xl">
                                {['Smiles featurization is independent of descriptors (Our USP).', '2D & 3D Featurization of the input molecules.', 'Selection of optimal descriptors.', 'State of The Art (SOTA) in 85% Properties.', 'Graph representation of the molecules for better structure info retention.'].map((item, idx) => (
                                    <li key={idx} className="flex items-center mb-2 py-2">
                                        <img src={shield} height={20} width={20} alt="Lab Icon" className="inline-block mr-2" />
                                        <span className="text-md text-[#BCCACA] ml-2">{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
 
                    {/* Retrosynthesis Section */}
                    <div className="flex flex-col-reverse lg:flex-row items-center justify-between p-6 lg:p-12 mt-12">
                        <div className={`w-full lg:w-1/2 pr-0 lg:pr-8 ${retroInView ? 'slide-in-left' : ''}`} ref={retroRef}>
                            <h4 className="text-2xl font-semibold mb-8 text-start">Retrosynthesis</h4>
                            <ul className="mb-6 text-justify text-xl">
                                {['Predictive ML algorithms to forecast pathways from synthesis data, optimizing reaction mechanisms.', 'Predictive Modeling for Reactions.', 'Dissects complex molecules into simpler precursors with a focus on efficiency and sustainability.', 'Applies graph theory and ML to identify efficient synthetic routes.', 'Utilizes MCDA for pathway selection, prioritizing synthetic accessibility.'].map((item, idx) => (
                                    <li key={idx} className="flex items-center mb-2 py-2">
                                        <img src={shield} height={20} width={20} alt="Lab Icon" className="inline-block mr-2" />
                                        <span className="text-md text-[#BCCACA] ml-2">{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={`w-full lg:w-1/2 mt-6 lg:mt-0 ${retroInView ? 'slide-in-right' : ''}`}>
                            <img src={retro} alt="Retrosynthesis" className="w-full lg:w-[70%] lg:h-[70%] h-auto max-w-md sm:max-w-lg lg:max-w-sm xl:max-w-md 2xl:max-w-lg rounded-[50px]" />
                        </div>
                    </div>
 
                    {/* Virtual Screening Section */}
                    <div className="flex flex-col lg:flex-row items-center justify-between p-6 lg:p-12 mt-12">
                        <div className={`w-full lg:w-1/2 mt-6 lg:mt-0 ${virtualInView ? 'slide-in-left' : ''}`} ref={virtualRef}>
                            <img src={virtual} alt="Virtual Screening" className="w-full lg:w-[70%] lg:h-[70%] h-auto max-w-md sm:max-w-lg lg:max-w-sm xl:max-w-md 2xl:max-w-lg rounded-[50px]" />
                        </div>
                        <div className={`w-full lg:w-1/2 pr-0 lg:pr-8 ml-6 ${virtualInView ? 'slide-in-right' : ''}`}>
                            <h4 className="text-2xl font-semibold mb-8 text-start">Virtual Screening</h4>
                            <ul className="mb-6 text-justify text-xl">
                                {['Systematically screens vast chemical libraries to identify potential drug candidates.', 'Advanced analytics dissect PK/PD and ADMET profiles with unparalleled precision.', 'AI strategies elevate compound selection, spotlighting efficacy and safety.', 'Adaptive algorithms boost selection speed, slashing computational load.'].map((item, idx) => (
                                    <li key={idx} className="flex items-center mb-2 py-2">
                                        <img src={shield} height={20} width={20} alt="Lab Icon" className="inline-block mr-2" />
                                        <span className="text-md text-[#BCCACA] ml-2">{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Platform;
