import React from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

const NewsDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
  
    // Check if location.state is available
    if (!location.state) {
      // Redirect the user to a fallback page, such as the homepage or news list
      navigate('/newslist');
      return null; // Prevent rendering the rest of the component
    }
  const { image, title, description, date } = location.state;

  return (
    <div className="bg-black min-h-screen font-sofia sm:px-8 lg:px-24 xl:px-32 ">
      {/* Gradient section */}
      <div className="p-3">
        <div className="w-full mx-auto">
          <h1 className="text-3xl md:text-4xl font-bold text-white  text-justify md:text-left">
            {title}
          </h1>
        </div>
      </div>

      {/* Content section */}
      <div className="w-full mx-auto p-2 md:p-8 bg-black rounded-lg text-justify shadow-lg">
        <p className="text-gray-400 mb-10 text-center md:text-left">
          Published on {date} 
        </p>
        <div className="leading-relaxed space-y-4 text-2xl text-justify text-white">
          <p>{description}</p>
        </div>
        <div className="mt-6 flex justify-center">
          <img 
            src={image}
            alt={title}
            className="rounded-lg shadow-md h-auto w-full object-cover"
          />
        </div>

        <div className="leading-relaxed space-y-4 text-2xl text-justify text-white">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
