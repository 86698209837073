import React from "react";
import Footer from "./Footer";
 
const TermsConditions=()=>{
    return(
        <div className="bg-black">
        <div class="terms-conditions bg-black text-white font-sofia px-32 h-[100%]">
    <h1 class="text-2xl font-bold mb-4">Terms & Conditions</h1>
 
    <p class="text-sm mb-2">Last Updated: May 2024</p>
 
    <h2 class="text-xl font-semibold mb-3">Overview</h2>
    <p class="mb-4">
        The following are the terms of an agreement between you and CENTELLA SCIENTIFIC Private Limited (referred to as CENTELLA hereafter). By accessing, or using this Web site, you acknowledge that you have read, understand, and agree to be bound by these terms and to comply with all applicable laws and regulations, including export and re-export control laws and regulations. If you do not agree to these terms, please do not use this Web site.
    </p>
    <p class="mb-4">
        CENTELLA may, without notice to you, at any time, revise these Terms of Use and any other information contained in this Web site. CENTELLA may also make improvements or changes in the products, services, or programs described in this site at any time without notice.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">General</h2>
    <p class="mb-4">
        This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled “Copyright and trademark information” for related information.
    </p>
    <p class="mb-4">
        CENTELLA grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of CENTELLA provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact. You may only use a crawler to crawl this Web site as permitted by this Web site’s robots.txt protocol, and CENTELLA Labs may block any crawlers at its sole discretion. The use authorized under this agreement is non-commercial in nature (e.g., you may not sell the content you access on or through this Web site.) All other use of this site is prohibited.
    </p>
    <p class="mb-4">
        Except for the limited permission in the preceding paragraph, CENTELLA does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Website or in any other media. Any software and other materials that are made available for downloading, access, or other use from this site with their own license terms will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Rules of Conduct</h2>
    <p class="mb-4">
        Your use of the CENTELLA website is subject to all applicable local, state, national, and international laws and regulations. By using this website, you agree not to violate any such laws and regulations. Any attempt to deliberately damage the CENTELLA website is a violation of criminal and civil laws, and CENTELLA reserves the right to seek damages to the fullest extent permitted by law.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Prohibited Conduct</h2>
    <p class="mb-4">
        Illegal Activities: You agree not to post or transmit any material or content that violates or infringes on the rights of others, promotes the use of illegal substances or activities, or is otherwise unlawful, threatening, abusive, harassing, defamatory, libelous, derogatory, invasive of privacy or publicity rights, vulgar, obscene, bigoted, hateful, profane, scandalous, pornographic, indecent, or otherwise objectionable. Any such actions that give rise to civil or criminal liability or violate any applicable law are strictly prohibited.
    </p>
    <p class="mb-4">
        Impersonation and Misrepresentation: You may not impersonate another user, use another user's ID, permit someone else to use your ID, attempt to capture or guess other users' passwords, forge headers, or otherwise manipulate identifiers to disguise the origin of any content you transmit. Conducting fraudulent business operations, promoting or concealing unlawful conduct, and other forms of misrepresentation are prohibited.
    </p>
    <p class="mb-4">
        Disruption and Harassment: You may not engage in any activity that restricts or inhibits other users from using or enjoying the CENTELLA website. This includes "hacking," "cracking," "spoofing," or defacing any portions of the website. Additionally, using the CENTELLA website to stalk or harass another person or entity is strictly prohibited.
    </p>
    <p class="mb-4">
        Unsolicited Content: You may not post or transmit any advertising or commercial solicitations, promotional materials related to websites or online services competitive with the CENTELLA website, or software/materials containing viruses, worms, time bombs, Trojan horses, or other harmful components. Posting political campaign materials, chain letters, mass mailings, spam mail, or using any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, "data mine," or in any way reproduce or circumvent the navigational structure or presentation of the CENTELLA website or its content is also prohibited.
    </p>
    <p class="mb-4">
        Information Harvesting: You may not harvest or collect information about website visitors without their express written consent.
    </p>
    <p class="mb-4">
        By adhering to these rules of conduct, you help ensure a safe and enjoyable experience for all users of the CENTELLA website. Violations of these rules may result in termination of your access to the website and potential legal action.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Disclaimer</h2>
    <p class="mb-4">
        CENTELLA SCIENTIFIC Private Limited ("CENTELLA") strives to ensure the accuracy of the information on this website. However, this site may occasionally contain technical inaccuracies or typographical errors. We do not guarantee the accuracy of any posted information. Please ensure you are using the most current pages of this website and verify the accuracy and completeness of the information before making decisions related to services, products, or other matters described on this and related websites.
    </p>
    <p class="mb-4">
        If any provision of these Terms of Use is found by a competent judicial authority to be unenforceable, the validity of the remaining provisions will remain unaffected, provided that the unenforceability does not materially affect the parties' rights under these Terms of Use.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Confidential Information</h2>
    <p class="mb-4">
        CENTELLA does not wish to receive confidential or proprietary information from you through our website. In the event of a merger, acquisition, or sale of assets involving CENTELLA, we will ensure the confidentiality of your personal information and notify affected users before personal information is transferred or becomes subject to a different privacy policy. We employ encryption to protect your data while in transit and review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems. Access to personal information is restricted to CENTELLA employees, contractors, and agents who need it to process it. Individuals with access are subject to strict contractual confidentiality obligations and may face disciplinary action or termination if they fail to meet these obligations. Your personal information will not be shared with companies, organizations, or individuals outside of CENTELLA.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Global Availability</h2>
    <p class="mb-4">
        Information published by CENTELLA on the Internet may include references or cross-references to CENTELLA products, programs, and services that are not announced or available in your country. Such references do not imply that CENTELLA intends to announce or make available these products, programs, or services in your country. Please consult your local CENTELLA representative for information on products, programs, and services available to you.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Business Relationships</h2>
    <p class="mb-4">
        This website may provide links or references to non-CENTELLA websites and resources. CENTELLA makes no representations, warranties, or endorsements regarding any non-CENTELLA websites or third-party resources that may be referenced, accessible from, or linked to any CENTELLA site. CENTELLA is not responsible for any transactions you may enter into with third parties, even if you learn of such parties (or use a link to such parties) from a CENTELLA site. When accessing a non-CENTELLA website, even one that may contain the CENTELLA logo, please understand that it is independent from CENTELLA, and CENTELLA does not control the content on that website. It is your responsibility to take precautions to protect yourself from viruses, worms, Trojan horses, and other potentially destructive programs, and to safeguard your information.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Linking to this site</h2>
    <p class="mb-4">
        All links to this website must be approved in writing by CENTELLA, except that CENTELLA consents to links where the link and the pages activated by the link do not:
    </p>
    <ul class="list-disc pl-6 mb-4">
        <li>create frames around any page on this Web site or use other techniques that alter in any way the visual presentation or appearance of any content within this site;</li>
        <li>misrepresent your relationship with CENTELLA;</li>
        <li>imply that CENTELLA approves or endorses you, your Web site, or your service or product offerings; and</li>
        <li>present false or misleading impressions about CENTELLA or otherwise damage the goodwill associated with the CENTELLA name or trademarks.</li>
    </ul>
    <p class="mb-4">
        As a further condition to being permitted to link to this site, you agree that CENTELLA may at any time, at its sole discretion, terminate permission to link to this website. In such event, you agree to immediately remove all links to this website and to cease any related use of CENTELLA trademarks.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Limitation of Liability</h2>
    <p class="mb-4">
        In no event will CENTELLA be liable to any party for any direct, indirect, incidental, special, exemplary, or consequential damages of any type whatsoever related to or arising from this website or any use of this website, or of any site or resource linked to, referenced, or accessed through this website, or for the use or downloading of, or access to, any materials, information, products, or services, including, without limitation, any lost profits, business interruption, lost savings or loss of programs or other data, even if CENTELLA is expressly advised of the possibility of such damages. This exclusion and waiver of liability apply to all causes of action, whether based on contract, warranty, tort, or any other legal theories.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Governing Law and Jurisdiction</h2>
    <p class="mb-4">
        This website is created and controlled by CENTELLA in India. As such, the laws of India will govern these disclaimers, terms, and conditions without giving effect to any principles of conflicts of laws. CENTELLA reserves the right to make changes to its website and these disclaimers, terms, and conditions at any time. CENTELLA makes no representation that materials on this website are appropriate or available for use outside of India, and access to them from territories where their content is illegal is prohibited. Those who choose to access this website from locations outside India do so on their initiative and are responsible for compliance with local laws.
    </p>
 
    <h2 class="text-xl font-semibold mb-3">Other Notices</h2>
    <p class="mb-4">
        This website may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled "Privacy Policy" for related information.
    </p>
</div>
<div className="mt-16 ">
        <Footer />
      </div>
 
        </div>
    )
}
 
export default TermsConditions;
 