import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import caseimg from '../images/case-study.png';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'; // Import arrow icons
// import '../style/home.css';
 
const CaseStudies = () => {
  const [openIndex, setOpenIndex] = useState(null);
 
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
 
  return (
    <div className="bg-black text-white p-8 px-6 font-sofia">
      {/* Header */}
      <div className="flex justify-between items-center mb-8 ">
        <div className="text-5xl font-bold   text-[#6FC5F0]">CASE STUDIES</div>
       <Link to='/case-study'>
       <div  className="text-xl text-white  hover:text-[#6FC5F0] tracking-[1px] font-medium mr-4"> View More  &rarr;</div>

       </Link> 
      </div>
 
      <p className="text-2xl  font-semibold mb-8">
        Explore Our AI-Integrated Science Case Studies
      </p>
 
      {/* Main Content */}
      <div className="flex flex-col md:flex-row gap-8">
        {/* Large Image */}
        <div className="flex-shrink-0">
          <div className="relative rounded-lg overflow-hidden">
            <div className="absolute inset-0 border-4 border-transparent rounded-lg border-bg-gradient-to-r from-black via-[#411D27] to-[#212337]"></div>
            <img
              src={caseimg}
              alt="Case Study"
              className="relative  z-10 w-full md:w-[550px] h-full rounded-lg"
            />
          </div>
        </div>
 
        {/* Case Study List */}
        <div className="flex flex-col w-full md:w-1/2 space-y-4 ">
          {/* Single Case Study */}
          {[
            {
              title: 'AI for Scientific Breakthroughs',
              content: 'Dive into how AI is transforming scientific research and discovery...'
            },
            {
              title: 'Where Science Meets AI',
              content: 'Discover compelling case studies showcasing our innovative solutions...'
            },
            {
              title: 'AI\'s Impact on Science',
              content: 'Learn about AI\'s role in advancing knowledge and breakthroughs...'
            }
          ].map((study, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg cursor-pointer border border-[#6FC5F0] "
              onClick={() => handleToggle(index)}
              style={{
                maxHeight: openIndex === index ? '100%' : '80px', // Control height based on toggle
                overflow: 'hidden',
              }}
            >
              <div className="flex items-center justify-between ">
                <h3 className="text-xl font-bold mb-2">{study.title}</h3>
                {openIndex === index ? (
                  <AiOutlineUp className="text-gray-400" />
                ) : (
                  <AiOutlineDown className="text-gray-400" />
                )}
              </div>
              {openIndex === index && (
                <p className="text-gray-400 mt-2">{study.content}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
 
export default CaseStudies;
 
 
 