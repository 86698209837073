import React, { useState } from 'react';
import Slider from 'react-slick';
import scale from '../images/scalable.png'
import bar from '../images/reduction.png'
import bar1 from '../images/bar1.png'
import seam from '../images/seamless.png'
import robut from '../images/recycle.png'
import Collabarators from '../images/collabration.png'
import Rt from '../images/Rt.png'
import cs from '../images/CS pencil.png'
import security1 from '../images/secuity1.png'
import security from '../images/security.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const KeyFeatures = () => {
    const [showAll, setShowAll] = useState(false);

    const features = [
        { title: "Scalable Architecture", icon: scale },
        { title: "High Performance", icon: bar1},
        { title: "Seamless Integrations", icon: seam },
        { title: "Robust Security & Compliance", icon: security1 },
        { title: "Customizable Solutions", icon: cs },
        { title: "Collaborative Workbench", icon: Collabarators },
        { title: "Drug Likeliness & Rapid DMTA iteration", icon: robut },
        { title: "5x Reduced Cost & Time", icon: bar },
        { title: "Real-time Experiment Validation", icon: Rt },
        { title: "Safety (30+ PK/ADMET end)", icon: security },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        customPaging: (i) => (
            <div
                style={{
                    width: "30px",
                    height: "2px",
                    background: "#C9C9C9",
                    margin: "0 5px",
                    marginTop: '30px'
                }}
            />
        ),
        appendDots: (dots) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                <ul style={{ display: "flex", gap: "10px" }}> {dots} </ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="bg-black text-white py-12 px-4 sm:px-8 lg:px-24 xl:px-32 font-sofia">
            <h2 className="text-5xl font-semibold mb-12 text-start text-[#6FC5F0]">Key Features and Benefits</h2>
            {showAll ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className={`p-6 rounded-[25px] hover:bg-[#2e2e36] hover:border-[#ffffff] transition duration-300 flex flex-col items-start justify-start border border-[#42424580] mx-auto w-full max-w-[203px] h-[170px] ${
                                index === features.length - 1 ? "col-span-full justify-self-center" : ""
                            }`}
                            style={{
                                background: "linear-gradient(180deg, rgba(54, 54, 62, 0.3) 0%, rgba(41, 41, 48, 0.3) 100%)",
                            }}
                        >
                            <img
                                src={feature.icon}
                                alt={feature.title}
                                height={100}
                                width={50}
                                className="text-5xl mb-2"
                            />
                            <h3 className="text-md text-[#C9C9C9] font-medium text-start">
                                {feature.title}
                            </h3>
                        </div>
                    ))}
                </div>
            ) : (
                <Slider {...settings} >
                    {features.slice(0, 10).map((feature, index) => (
                        <div
                            key={index}
                            className="p-6 rounded-[25px] flex flex-col items-start justify-start border border-[#42424580] mx-auto w-full max-w-[203px] h-[170px] hover:bg-[#2e2e36] hover:border-[#ffffff] transition duration-300"
                            style={{
                                background: "linear-gradient(180deg, rgba(54, 54, 62, 0.3) 0%, rgba(41, 41, 48, 0.3) 100%)",
                            }}
                        >
                            <img
                                src={feature.icon}
                                alt={feature.title}
                                height={100}
                                width={50}
                                className="text-5xl mb-2"
                            />
                            <h3 className="text-md text-[#C9C9C9] font-medium text-start">
                                {feature.title}
                            </h3>
                        </div>
                    ))}
                </Slider>
            )}

            <div className="text-center mt-6">
                <button
                    className="bg-[#000000] text-white py-2 px-4 mt-10 rounded-sm border border-white hover:bg-white hover:text-black transition duration-300"
                    onClick={() => setShowAll(!showAll)}
                >
                    {showAll ? "Show Less" : "View All"}
                </button>
            </div>
        </div>
    );
};

export default KeyFeatures;
